<template>
  <BaseListPage
    locale-section="pages.herGreatBritain"
    route="herGreatBritainDetail"
  >
    <HerGreatBritainList />
  </BaseListPage>
</template>

<script>
export default {
  name: "HerGreatBritainPage",
  components: {
    BaseListPage: () => import("@/components/BaseListPage"),
    HerGreatBritainList: () => import("./HerGreatBritainList")
  }
};
</script>
